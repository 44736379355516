import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CounterComponent } from './counter.component';

@Injectable({
  providedIn: 'root',
})
export class TimersClass {

  // private subscriptions: Subscription[] = {};
  // private subscriptionIds: any[] = {};
  // private counter: CounterComponent[] = {};
  // private counterList: CounterComponent[] = {};
  //
  //
  // public startTimer(id: any) {
  //   this.counterList[id] = new CounterComponent();
  //   this.counterList[id].startinterval();
  //   this.subscriptions['' + id + ''] = this.counterList[id].counter$.subscribe((response) => { console.log(response); });
  //   this.subscriptionIds[id] = id;
  //   localStorage.setItem('suscriptions', JSON.stringify(this.subscriptionIds));
  //   console.log(this.subscriptions);
  // }
  //
  // public stopTimer(id: any) {
  //   this.counterList[id].stop();
  //   this.subscriptions[id].unsubscribe();
  // }
  //
  // ngOnDestroy() {
  //   this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  // }

}
